import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import { Index } from 'elasticlunr'
import queryString from 'query-string'
import Layout from "../components/layout2"
import HomeBanner from "../components/homeBanner2"
import Mapa from '../components/mapa'
import SEO from "../components/seo"
import {
    Container
} from 'react-bootstrap'

export default class search extends Component {
    constructor(props) {
        super(props)
        this.index = null
        this.state = {
            query: ``,
            results: []
        }
    }

    getOrCreateIndex() {
        if(this.index)
            return this.index
        return Index.load(this.props.data.siteSearchIndex.index);
    }

    search = (evt) => {
        const query = evt.target.value
        this.index = this.getOrCreateIndex()
        this.setState({
            query,
            results: this.index.search(query).map(({ ref }) => this.index.documentStore.getDoc(ref)),
        })
    }

    componentDidMount() {
        const parsed = queryString.parse(window.location.search)
        if(parsed.q) {
            const query = parsed.q
            this.index = this.getOrCreateIndex()
            this.setState({
                query: parsed.q,
                results: this.index.search(query).map(({ ref }) => this.index.documentStore.getDoc(ref)),
            })

        }
    }

    render() {
        return (
            <Layout>
                <SEO title="Andes CCI - Buscasdor de cursos" 
                description="Andes Capacitación Ltda. es una empresa que ofrece servicios de capacitación a empresas y personas que trabajan en la industria agroindustrial, pesquera, minera, metalmecánica y metalúrgica.
                Las Capacitaciones se realizan en modalidad: abierta, cerrada y personalizada, cuyos contenidos son adaptados de acuerdo a las necesidades y requerimientos específicos de los clientes que los solicitan.
                Andes Capacitación Ltda. se encuentra certificada en la Norma NCH 2728 del 2015, acreditada en el Registro del Servicio Nacional de Capacitación y Empleo (SENCE). La modalidad de cursos a Empresas, está acogida a Franquicia Tributaria SENCE."
                keywords={[`andes`, 
                `capacitacion`,
                `efectiva`,
                `andes capacitacion`,
                `capacitacion efectiva`,
                `andes capacitacion efectiva`,
                `sence`,
                `capacitacion sence`,
                `norma`,
                `NCH:2728`,
                `norma nch:2728`,
                `otec`,
                `atacama`,
                `otec atacama`,
                `copiapo`,
                `otec copiapo`,
                `chile`,
                `mineria`,
                `otec mineria`,
                `camion`,
                `camion pluma`,
                `cargador`,
                `cargador frontal`,
                `minicargador`,
                `minicargador frontal`,
                `extintor`,
                `extintores`,
                `primeros auxilios`,
                `auxilios`,
                `altura`,
                `trabajo en altura`,
                `trabajo altura`,
                `maquinaria`,
                `pesada`,
                `maquinaria pesada`,
                `manejo`,
                `alta`,
                `montana`,
                `manejo alta montana`,
                `manejo defensivo`,
                `manejo defensivo alta montana`,
                `izaje`,
                `rigger`,
                `izaje y rigger`,
                `transporte`,
                `almacenamiento`,
                `sustancias`,
                `peligrosas`,
                `manejo sustancias`,
                `manejo sustancias peligrosas`,
                `almacenamiento sustancias`,
                `almacenamiento sustancias peligrosas`,
                `transporte sustancias`,
                `transporte sustancias peligrosas`,
                `manejo, almacenamiento y transporte de sustancias`,
                `manejo, almacenamiento y transporte de sustancias peligrosas`,
                `manejo, transporte y almacenamiento de sustancias`,
                `manejo, transporte y almacenamiento de sustancias peligrosas`,
                `transporte, manejo y almacenamiento de sustancias`,
                `transporte, manejo y almacenamiento de sustancias peligrosas`,
                `transporte, almacenamiento y manejo de sustancias`,
                `transporte, almacenamiento y manejo de sustancias peligrosas`,
                `almacenamiento, transporte y manejo de sustancias`,
                `almacenamiento, transporte y manejo de sustancias peligrosas`,
                `almacenamiento, manejo y transporte de sustancias`,
                `almacenamiento, manejo y transporte de sustancias peligrosas`,
                `tecnicas`,
                `tegnicas de operacion`,
                `tecnicas en la operacion`,
                `tecnicas de operacion de camion`,
                `tecnicas en la operacion de camion`,
                `tecnicas de operacion de camion pluma`,
                `tecnicas en la operacion de camion pluma`,
                `retroexcavadora`,
                `operacion segura`,
                `puente grua`,
                `operacion puente grua`,
                `operacion segura de puente grua`,
                `tecnicas de operacion segura`,
                `tecnicas de operacion segura de retroexcavadora`,
                `plataformas elevadoras`,
                `operacion segura de plataformas elevadoras`,
                `tecnicas de operacion segura de plataformas elevadoras`,
                `gruas`,
                `levante`,
                `grua`,
                `levante de material`,
                `izaje de material`,
                `levante e izaje`,
                `izaje y levante`,
                `izaje y levante con gruas`,
                `levante e izaje con gruas`,
                `levante e izaje de material`,
                `izaje y levante de material`,
                `levante e izaje de material con gruas`,
                `izaje y levante de material con gruas`,
                `tecnicas de izaje y levante con gruas`,
                `tecnicas de levante e izaje con gruas`,
                `tecnicas de izaje y levante de material`,
                `tecnicas de levante e izaje de material`,
                `tecnicas de izaje y levante de material con gruas`,
                `tecnicas de levante e izaje de material con gruas`,
                `operacion cargador`,
                `operacion minicargador`,
                `operacion cargador frontal`,
                `operacion segura cargador frontal`,
                `operacion minicargador frontal`,
                `operacion segura minicargador`,
                `operacion segura minicargador frontal`,
                `tecnicas de operacion cargador`,
                `tecnicas de operacion cargador frontal`,
                `tecnicas de operacion segura cargador`,
                `tecnicas de operacion segura cargador frontal`,
                `tecnicas de operacion minicargador`,
                `tecnicas de operacion minicargador frontal`,
                `tecnicas de operacion segura minicargador`,
                `tecnicas de operacion segura minicargador frontal`,
                `mantencion`,
                `reparacion`,
                `operacion y mantencion`,
                `mantencion y operacion`,
                `operacion, mantencion y reparacion`,
                `operacion, reparacion y mantencion`,
                `mantencion, operacion y reparacion`,
                `mantencion, reparacion y operacion`,
                `reparacion, operacion y mantencion`,
                `reparacion, mantencion y operacion`,
                `tecnicas de operacion, mantencion y reparacion`,
                `tecnicas de operacion, reparacion y mantencion`,
                `tecnicas de mantencion, operacion y reparacion`,
                `tecnicas de mantencion, reparacion y operacion`,
                `tecnicas de reparacion, operacion y mantencion`,
                `tecnicas de reparacion, mantencion y operacion`,
                `sistemas de aire acondicionado`,
                `aire acondicionado`,
                `operacion y mantencion de aire acondicionado`,
                `mantencion y operacion de aire acondicionado`,
                `operacion y mantencion de sistemas de aire acondicionado`,
                `mantencion y operacion de sistemas de aire acondicionado`,
                `operacion, mantencion y reparacion de aire acondicionado`,
                `operacion, reparacion y mantencion de aire acondicionado`,
                `mantencion, operacion y reparacion de aire acondicionado`,
                `mantencion, reparacion y operacion de aire acondicionado`,
                `reparacion, operacion y mantencion de aire acondicionado`,
                `reparacion, mantencion y operacion de aire acondicionado`,
                `tecnicas de operacion, mantencion y reparacion de aire acondicionado`,
                `tecnicas de operacion, reparacion y mantencion de aire acondicionado`,
                `tecnicas de mantencion, operacion y reparacion de aire acondicionado`,
                `tecnicas de mantencion, reparacion y operacion de aire acondicionado`,
                `tecnicas de reparacion, operacion y mantencion de aire acondicionado`,
                `tecnicas de reparacion, mantencion y operacion de aire acondicionado`,
                `operacion y mantencion de sistemas de sistemas de aire acondicionado`,
                `mantencion y operacion de sistemas de sistemas de aire acondicionado`,
                `operacion, mantencion y reparacion de sistemas de aire acondicionado`,
                `operacion, reparacion y mantencion de sistemas de aire acondicionado`,
                `mantencion, operacion y reparacion de sistemas de aire acondicionado`,
                `mantencion, reparacion y operacion de sistemas de aire acondicionado`,
                `reparacion, operacion y mantencion de sistemas de aire acondicionado`,
                `reparacion, mantencion y operacion de sistemas de aire acondicionado`,
                `tecnicas de operacion, mantencion y reparacion de sistemas de aire acondicionado`,
                `tecnicas de operacion, reparacion y mantencion de sistemas de aire acondicionado`,
                `tecnicas de mantencion, operacion y reparacion de sistemas de aire acondicionado`,
                `tecnicas de mantencion, reparacion y operacion de sistemas de aire acondicionado`,
                `tecnicas de reparacion, operacion y mantencion de sistemas de aire acondicionado`,
                `tecnicas de reparacion, mantencion y operacion de sistemas de aire acondicionado`,
                `curso camion`,
                `curso camion pluma`,
                `cursos`,
                `curso`,
                `curso extintores`]} />
                <HomeBanner />
                <div id="nuestros-cursos">
                    <Container>
                        <h1 className="font-weight-bold" style={{ color: "#FF6751" }}>Buscador de cursos</h1>
                        <input className="form-control" type="text" placeholder="Cursos, capacitaciónes, seminarios, diplomados..." value={this.state.query} onChange={this.search} style={{ marginTop: "3%" }} />
                        <h1 style={{ color: "#FF6751", fontWeight: "lighter", marginTop: "2%", marginBottom: "1%", fontSize: "14px" }}>Resultados busqueda.</h1>
                        <div id="nuestros-cursos-text" className="text-justify puntos-cursos" style={{ paddingLeft: "0" }}>
                            <ul style={{ marginTop: "3%" }}>
                                {this.state.results.length>0?
                                this.state.results.map( page => (
                                    <li style={{ marginBottom: "1%" }}>
                                        <Link to={page.path} style={{ color: "#000000", textDecoration: "underline" }}>{page.curso}</Link>: <span style={{ color: "#525252" }}>{page.descripcion}</span>
                                    </li>
                                )):<p>No se encontraron resultados.</p>}
                            </ul>
                        </div>
                    </Container>
                </div>
                <Mapa />
            </Layout>
        )
    }
}

export const query = graphql`
query SearchIndexQuery {
    siteSearchIndex {
        index
    }
}
`