import React, { Component } from 'react'
import {
    Carousel,
    InputGroup,
    FormControl,
    Button
} from 'react-bootstrap'
import landing_1 from '../images/landing_1.png'
import landing_2 from '../images/landing_2.png'
import landing_3 from '../images/landing_3.png'

export default class homeBanner2 extends Component {
    render() {
        return (
            <div id="home-banner-container">
                <Carousel id="home-banner">
                    <Carousel.Item>
                        <img className="d-block w-100" src={landing_1} alt="Landing 1" />
                        <Carousel.Caption>
                            <h1>ENCUENTRA EL CURSO<br />IDEAL PARA TI</h1>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100" src={landing_2} alt="Landing 1" />
                        <Carousel.Caption>
                            <h1>LA MEJOR OPCIÓN PARA<br />SEGUIR APRENDIENDO</h1>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100" src={landing_3} alt="Landing 1" />
                        <Carousel.Caption>
                            <h1>CAPACITAMOS DE FORMA<br />EFECTIVA</h1>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
        )
    }
}
